import React from 'react'

const Modal = ({ title, message, onConfirm, onClose, categories }) => {
    const handleConfirm = () => {
        onConfirm(categories)
    }

    return (
        <div className="fixed inset-0 flex z-50 items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg">
                <h2 className="text-lg font-bold">{title}</h2>
                <p className="mt-4">{message}</p>
                <div className="mt-4 flex justify-end space-x-2">
                    <button onClick={onClose} className="px-4 py-2 bg-gray-500 text-white rounded">
                        Cancel
                    </button>
                    <button
                        onClick={handleConfirm}
                        className="px-4 py-2 bg-main text-white rounded"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Modal
