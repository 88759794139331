import { useEffect, useRef } from 'react'
import { MdOutlineFileDownload } from 'react-icons/md'

export const DownloadDocxButton = ({
    event,
    eventKey,
    selectedEvent,
    openPopupIndexDocx,
    setOpenPopupIndexDocx,
    setModalDocx,
    setSelectedDownload,
    setSelectedEvent,
}) => {
    const ref = useRef(null)

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenPopupIndexDocx(null)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleDocxClick = ({ id, categories }) => {
        setSelectedDownload({
            id,
            categories,
            sort: 'desc',
        })
        setModalDocx(true)
    }

    const handlePopupToggle = () => {
        if (openPopupIndexDocx === eventKey) {
            setOpenPopupIndexDocx(null)
        } else {
            setOpenPopupIndexDocx(eventKey)
            setSelectedEvent(event)
        }
    }

    return (
        <div className="flex gap-2 items-center justify-center">
            <button
                onClick={handlePopupToggle}
                className="text-[10px] relative whitespace-nowrap flex items-center gap-2 h-fit cursor-pointer border-main rounded-md border py-2 px-4 my-2"
            >
                DOCX
                <span className="text-[12px]">
                    <MdOutlineFileDownload />
                </span>
                {openPopupIndexDocx === eventKey && (
                    <div
                        ref={ref}
                        className="z-50 mt-[4.5rem] -ml-9 absolute flex flex-col text-left rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main"
                    >
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() =>
                                handleDocxClick({
                                    id: selectedEvent?.event_id,
                                    categories: ['General Discussions'],
                                })
                            }
                        >
                            General Discussions Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() =>
                                handleDocxClick({
                                    id: selectedEvent?.event_id,
                                    categories: ['Chatroom KIQs'],
                                })
                            }
                        >
                            Chatroom Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() =>
                                handleDocxClick({
                                    id: selectedEvent?.event_id,
                                    categories: ['My KIQs'],
                                })
                            }
                        >
                            My KIQs Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() =>
                                handleDocxClick({
                                    id: selectedEvent?.event_id,
                                    categories: ['SnapIntel'],
                                })
                            }
                        >
                            SnapIntel Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                            onClick={() =>
                                handleDocxClick({
                                    id: selectedEvent?.event_id,
                                    categories: [
                                        'General Discussions',
                                        'Chatroom KIQs',
                                        'My KIQs',
                                        'SnapIntel',
                                    ],
                                })
                            }
                        >
                            Event Docx{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                    </div>
                )}
            </button>
        </div>
    )
}

export const PDFCompButton = ({
    openPopupIndex,
    event,
    eventKey,
    setSelectedEvent,
    setSelectedDownload,
    setModalOpen,
    setOpenPopupIndex,
    selectedEvent,
}) => {
    const ref = useRef(null)

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenPopupIndex(null)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handlePDFClick = ({ id, categories }) => {
        setSelectedDownload({
            id,
            categories,
            sort: 'desc',
        })
        setModalOpen(true)
    }

    const handlePopupToggle = () => {
        if (openPopupIndex === eventKey) {
            setOpenPopupIndex(null)
        } else {
            setOpenPopupIndex(eventKey)
            setSelectedEvent(event)
        }
    }
    return (
        <div>
            <button
                onClick={handlePopupToggle}
                className="text-xs relative whitespace-nowrap flex items-center gap-2 h-fit cursor-pointer border-main rounded-md border py-2 px-4 my-2"
            >
                PDF
                <span>
                    <MdOutlineFileDownload />
                </span>
                {openPopupIndex === eventKey && (
                    <div
                        ref={ref}
                        className="animate_fade_up z-0 mt-[5rem] -ml-20 absolute transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col text-left rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main"
                    >
                        <button
                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                            onClick={() =>
                                handlePDFClick({
                                    id: selectedEvent?.event_id,
                                    categories: ['General Discussions'],
                                })
                            }
                        >
                            General Discussions PDF <MdOutlineFileDownload className="text-lg" />
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                            onClick={() =>
                                handlePDFClick({
                                    id: selectedEvent?.event_id,
                                    categories: ['Chatroom KIQs'],
                                })
                            }
                        >
                            Chatroom PDF{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                            onClick={() =>
                                handlePDFClick({
                                    id: selectedEvent?.event_id,
                                    categories: ['My KIQs'],
                                })
                            }
                        >
                            My KIQs PDF{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                            onClick={() =>
                                handlePDFClick({
                                    id: selectedEvent?.event_id,
                                    categories: ['SnapIntel'],
                                })
                            }
                        >
                            SnapIntel PDF{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                        <button
                            className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                            onClick={() =>
                                handlePDFClick({
                                    id: selectedEvent?.event_id,
                                    categories: [
                                        'General Discussions',
                                        'Chatroom KIQs',
                                        'My KIQs',
                                        'SnapIntel',
                                    ],
                                })
                            }
                        >
                            Event PDF{' '}
                            <span className="text-lg">
                                <MdOutlineFileDownload />
                            </span>
                        </button>
                    </div>
                )}
            </button>
        </div>
    )
}
