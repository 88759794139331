import { useAtomValue } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import { CiCircleQuestion } from 'react-icons/ci';
import { IoIosAdd } from 'react-icons/io';
import {  useMutation } from 'react-query';
import { createPrompts } from 'services/request/Dashboard';
import { authAtom } from 'store/authAtom';
import { useAiSummaryContext } from 'store/context/AiSummaryContextProvider';

export const AiModal = ({ setIsModalOpen, confirmDownload }) => {
  const { prompts: data, isAiEnabled } = useAiSummaryContext()
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [isCustomInputModalOpen, setIsCustomInputModalOpen] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [customTitle, setCustomTitle] = useState('');
  const [customTemp, setCustomTemp] = useState('');
  const [customDescription, setCustomDescription] = useState('');
  const [customText, setCustomText] = useState('');
  const [activePromptDetails, setActivePromptDetails] = useState(null);
  const [customPrompts, setCustomPrompts] = useState([]);
  const { user } = useAtomValue(authAtom);
  const [toggle, setToggle] = useState(false)
  const checkboxRef = useRef(null)




  useEffect(() => {
    const handleClickOutside = (event) => {
        if (checkboxRef.current && !checkboxRef.current.contains(event.target)) {
          setToggle(false)
        }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
        document.removeEventListener('click', handleClickOutside)
    }
}, [])
  const promptsData = data?.prompts || {};
  const isAdmin = user?.role === 'admin';

  const selectedPrompts = isAdmin
    ? promptsData.defaultPrompt || []
    : [
      ...(promptsData.defaultPrompt || []),
      ...(promptsData.companyPrompt || []),
    ];



  // const prompts = data?.prompts || []; 

  const mutation = useMutation(createPrompts, {
    onSuccess: (newPrompt) => {
      setCustomPrompts((prev) => {
        if (prev.some((prompt) => prompt.title === newPrompt.title)) {
          return prev;
        }
        return [...prev, newPrompt];
      });
      setSelectedPrompt(newPrompt);
      setIsCustomInputModalOpen(false);
    },
    onError: (error) => {
      console.error('Error creating prompt:', error);
      alert('Failed to create prompt. Please try again.');
    },
  });

  const handleCustomInputConfirm = () => {
    if (customTitle.trim() && customText.trim()) {
      const newCustomPrompt = {
        title: customTitle.trim(),
        temperature: customTemp.trim() || 'N/A',
        description: customDescription.trim() || 'Custom Prompt',
        prompt_text: customText.trim(),
      };

      mutation.mutate(newCustomPrompt, {
        onSuccess: () => {
          setSelectedPrompt(newCustomPrompt)
          setCustomPrompts((prev) => [...prev, newCustomPrompt])
          setIsCustomInputModalOpen(false);
        },
      });
    } else {
      alert('Please enter a valid title and prompt text.');
    }
  };


  const handleYesClick = () => {
    setIsPromptModalOpen(true);
  };

  const handleConfirmPrompt = () => {
    if (selectedPrompt) {
      confirmDownload(isAiEnabled, selectedPrompt);
      setIsModalOpen(false);
    } else {
      alert('Please select or input a prompt.');
    }
  };


  const togglePromptDetails = (prompt) => {
    setActivePromptDetails(activePromptDetails === prompt ? null : prompt);
  };

  // const mergedPrompts = [
  //   ...prompts,
  //   ...customPrompts.filter(
  //     (customPrompt) => customPrompt?.title?.trim() && !prompts.some((prompt) => prompt.title === customPrompt.title)
  //   ),
  // ];

  const mergedPrompts = [
    ...selectedPrompts,
    ...customPrompts.filter(
      (customPrompt) =>
        customPrompt?.title?.trim() &&
        !selectedPrompts.some((prompt) => prompt.title === customPrompt.title)
    ),
  ];

  return isAiEnabled && (
    <>
      {!isPromptModalOpen && !isCustomInputModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96 relative">
            <p
              className="absolute top-2 right-2 text-lg opacity-70 hover:opacity-100 text-slate-500 cursor-pointer"
              onClick={() => setIsModalOpen(false)}
            >
              <AiOutlineCloseCircle />
            </p>
            <h2 className="text-lg font-semibold text-center mb-4">
              Do you want your file to be summarized by AI?
            </h2>
            <div className="flex justify-center gap-4">
              <button
                className="bg-main text-white px-4 py-2 rounded-md hover:bg-main-dark transition"
                onClick={handleYesClick}
              >
                Yes
              </button>
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition"
                onClick={() => confirmDownload(false, selectedPrompt)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {isPromptModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-h-350px  w-6/12 relative">
            <p
              className="absolute top-2 right-2 text-lg opacity-70 hover:opacity-100 text-slate-500 cursor-pointer"
              onClick={() => setIsModalOpen(false)}
            >
              <AiOutlineCloseCircle />
            </p>
            <h2 className="text-lg font-semibold text-center mb-4">
              Which prompt do you want to select?
            </h2>
            <div className="flex flex-col gap-3 mb-4">
              <button
                className="bg-gray-200 flex w-fit items-center justify-end text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 
                transition"
                onClick={() => setIsCustomInputModalOpen(true)}
              >
                <IoIosAdd /> Create Prompt
              </button>
              <div className='max-h-60 overflow-y-auto p-2'>
                {mergedPrompts.map((prompt, index) => (
                  <>
                  <div key={index} className="flex items-center gap-2 relative">
                    <input
                      type="radio"
                      name="prompt"
                      value={prompt.title}
                      checked={selectedPrompt?.title === prompt.title}
                      onChange={() => setSelectedPrompt(prompt)}
                      className="form-radio h-5 w-5 text-main"
                    />
                    <span className="text-gray-700">{prompt.title}</span>
                    <BsThreeDots
                      className="text-gray-600 cursor-pointer ml-auto"
                      onClick={() => togglePromptDetails(prompt)}
                    />
                   
                  </div>
                  {activePromptDetails === prompt && (
                      <div className="absolute bg-white right-7 shadow-lg border rounded-md p-3 w-8/12 h-fit max-h-60 z-50 
                      overflow-y-auto">
                        <h4 className="font-semibold text-gray-800 mb-2">{prompt.title}</h4>
                        <p className="text-gray-600 text-sm mb-2">Temperature: {prompt.temperature}</p>
                        <p className="text-gray-600 text-sm mb-2">Description: {prompt.description}</p>
                        <p className="text-gray-600 text-sm">Prompt Text:</p>
                        <p className="text-gray-600 text-sm ml-3">{prompt.prompt_text}</p>
                        <p
                          className="absolute top-2 right-2 text-lg opacity-70 hover:opacity-100 text-slate-500 cursor-pointer"
                          onClick={() => setActivePromptDetails(null)}
                        >
                          <AiOutlineCloseCircle />
                        </p>
                      </div>
                    )}
                  </>

                ))}
              </div>
            </div>
            <div className="flex gap-4 items-center justify-center mt-5">
              <button
                className="bg-main text-white px-4 py-2 rounded-md hover:bg-main-dark transition"
                onClick={handleConfirmPrompt}
              >
                Confirm
              </button>
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition"
                onClick={() => setIsPromptModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isCustomInputModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-5/12 relative">
            <p
              className="absolute top-2 right-2 text-lg opacity-70 hover:opacity-100 text-slate-500 cursor-pointer"
              onClick={() => setIsCustomInputModalOpen(false)}
            >
              <AiOutlineCloseCircle />
            </p>
            <h2 className="text-lg font-semibold text-center mb-4">Enter Your Custom Prompt</h2>
            <label className="text-slate-500 font-semibold">Title</label>
            <input
              className="w-full p-2 border mb-2 border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400"
              onChange={(e) => setCustomTitle(e.target.value)}
            />
            <div className='flex gap-1'   >
            <label className="text-slate-500 font-semibold">Temperature </label>
            <div ref={checkboxRef}>
            <CiCircleQuestion className='text-lg mt-[2px]'  onClick={() => setToggle(!toggle)}/>
            </div>
            </div>

            {toggle && (
              <div className=" z-20 absolute w-7/12 mt-1 transition-all gap-2 duration-300 cursor-pointer hover:bg-opacity-100 flex 
              flex-col border rounded-sm h-fit pt-2  px-1 bg-white shadow "
           >

              <p className='text-sm'>
                Controls the creativity of the response. Lower values (0.0-0.5) make the response more predictable and accurate. Higher 
                values (0.6-1.0) make the response more creative and varied.
              </p>
              </div>
            )}
            
            <input
              className="w-full p-2 border mb-2 border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400"
              placeholder="0.00 - 1.00"
              type="number" step={0.1}               onChange={(e) => setCustomTemp(e.target.value)}
            />
            <label className="text-slate-500 font-semibold">Description (0ptional)</label>
            <textarea
              className="w-full p-2 border mb-2 border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400"
              onChange={(e) => setCustomDescription(e.target.value)}
            />
            <label className="text-slate-500 font-semibold">Prompt Text</label>
            <textarea
              className="w-full p-2 border mb-4 border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-400"
              onChange={(e) => setCustomText(e.target.value)}
            />
            <div className="flex gap-4 items-center justify-center mt-5">
              <button
                className="bg-main text-white px-4 py-2 rounded-md hover:bg-main-dark transition"
                onClick={handleCustomInputConfirm}
                disabled={mutation.isLoading}
              >
                {mutation.isLoading ? 'Loading...' : 'Confirm'}
              </button>
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition"
                onClick={() => setIsCustomInputModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
